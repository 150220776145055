import React from 'react'
import MapContainer from './MapContainer'

const Divingmap = () => {


    return (
        <div className="container-fluid px-0">
           <div className="mapframe">
               <MapContainer />
           </div>
        </div>
    )
}

export default Divingmap
